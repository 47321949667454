import '../styles/globals.css';
import type { AppLayoutProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import React, { ReactNode } from 'react';
import { ThemeProvider } from 'next-themes';
import {useRouter} from 'next/router';
import { SWRConfig } from 'swr';
import fetcher from 'lib/fetcher';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppLayoutProps) {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  const router = useRouter();
  return (
    
    <ThemeProvider attribute="class" forcedTheme={router.pathname.includes("customer/") ? 'customer' : undefined} themes={['light', 'dark', 'customer']}>
      <SessionProvider session={session}>
        <SWRConfig value={{ fetcher: fetcher }}>
          {getLayout(<Component {...pageProps} />)}
  <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/39874897.js"></script>
        </SWRConfig>
      </SessionProvider>
    </ThemeProvider>
  );
}

export default MyApp;
