// fetcher.ts

const fetcher = (...args: [RequestInfo, RequestInit | undefined]) =>
  fetch(...args).then((res) => {
    if (!res.ok) throw new Error(res.status.toString());
    return res.json();
  });
export default fetcher;

export const gqlFetcher = async (query: string, variables = {}) => {
  const token = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN;
  if (!token) {
    throw new Error('Shopify Storefront API token is missing.');
  }

  const url = `${process.env.NEXT_PUBLIC_SHOPIFY_URL}/api/2024-10/graphql.json`; // Storefront API URL

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': token, // Correct token header for Storefront API
    },
    body: JSON.stringify({ query, variables }),
  });

  if (!res.ok) {
    throw new Error(`GraphQL error: ${res.statusText}`);
  }

  const data = await res.json();

  if (data.errors) {
    throw new Error(JSON.stringify(data.errors));
  }

  return data.data;
};
